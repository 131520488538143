.main-titel{
    font-size: 50px;
}
.Login-true{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    padding: 2vb;
    width: 100%;
    height: 300px;
    border-radius: 10px;
}
.subtitel{
    bottom: 0;
}
.loginStatus{
    color: #A6BF4B;
}
.button-primary {
    background-color: #282c34;
}
.Login-false{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    padding: 0;
    width: 100%;
    height: 300px;
    border-radius: 10px;
}
.element1-1{
    color: #84A429;
    font-size: 1.4rem;
    font-weight: 900;
}
.element1-2{
    font-size: 0.9rem;
}

.callback_button {
    margin-top: 20px;
}

.errormark-container {
    margin-bottom: 20px;
}
.element2-1{
    color: #e84747;
    font-size: 1.4rem;
    font-weight: 900;
}
.element2-2{
    font-size: 0.9rem;
    
}