/* チェックマーク */
  .checkmark {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
  }
  .checkmark_stem {
    width: 12px;
    height: 50px;
    background-color: #84A429;
    position: absolute;
    top: 40%;
    left: 50%;
    transform-origin: bottom left;
    transform: translateX(-50%) rotate(-45deg) scaleY(0); /* 初期状態で非表示 */
    animation: draw-stem 1.0s forwards; /* アニメーションを追加 */
  }
  .checkmark_kick {
    width: 80px;
    height: 12px;
    background-color: #84A429;
    position: absolute;
    top: 25%;
    left: 60%;
    transform-origin: bottom right;
    transform: translateX(-50%) rotate(-45deg) scaleX(0); /* 初期状態で非表示 */
    animation: draw-kick 0.5s forwards; /* アニメーションを追加 */
  }
  @keyframes draw-stem {
    0% {
      transform: translateX(-50%) rotate(-45deg) scaleY(0); /* 非表示 */
    }
    100% {
      transform: translateX(-50%) rotate(-45deg) scaleY(1); /* 表示 */
    }
  }
  @keyframes draw-kick {
    0% {
      transform: translateX(-50%) rotate(-45deg) scaleX(0); /* 非表示 */
    }
    100% {
      transform: translateX(-50%) rotate(-45deg) scaleX(1); /* 表示 */
    }
  }

  /* エラーマーク */
  .errormark {
    margin: 10px;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: inline-block;
    border: 10px solid #e84747;
    position: relative;
  }
  .errormark_kick {
    width: 95px;
    height: 10px;
    background-color: #e84747;
    position: absolute;
    top: 1%;
    left: 38%;
    transform-origin: bottom right;
    transform: translateX(-50%) rotate(-45deg) scaleX(0); /* 初期状態で非表示 */
    animation: draw-kick 1.0s forwards; /* アニメーションを追加 */
  }