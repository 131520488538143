.App {
  text-align: center;
  font-family: "Noto Sans JP", sans-serif;
  font-optical-sizing: auto;
}
body, html {
  width: 100%;
  height: 100%;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.main-titel{
  color: #A6BF4B;
}
.loginButton{
  display: flex;
  justify-content: center;
}

.title-image {
  margin-bottom: 20px;
}
.bottom-image {
  position: fixed;
  object-fit: contain;
  background-repeat: no-repeat;
  bottom: 0px;
  left: 0;
  height: 15%;
  width: 100%;
  z-index: -1;
}
.element-a{
  color: #282c34;
  padding: 10px;
}
.element-div{
  display: flex;
  justify-content: center;
  position: fixed;
  margin: auto;
  width: 100%;
  left: 50%; /* align to the horizontal center */
  transform: translateX(-50%); /* adjust to center */
  padding: 2px;
  bottom: 10px;
  text-align: center;
  font-size: small;
  text-wrap: nowrap;
  /* background-color: #A6BF4B; */
}

.element-div{
  display: flex;
  flex-direction: column;

}

.btnx--outline-indigo{
  color: var(--bs-indigo); background:var(--bs-white); border: 2px solid #9FA8DA; 
}
.btnx--outline-indigo :hover{
color: var(--bs-indigo); background:var(--bs-white); border: 2px solid #434657; 
}

@media screen and(max-width: 480px) {
  img {
  border: 0;
  }
  .bottom-image {
    height: 15%;
  }
  }
@media screen and(min-width: 800px) {
  img {
  border: 1px solid red;
  }
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@-webkit-keyframes AnimationName {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}
@-moz-keyframes AnimationName {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}
@keyframes AnimationName {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}